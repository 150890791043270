import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import styled from "styled-components"

const Intro = styled.div`
    color: #333;
    font-size: 1.6rem;
    line-height: 1.3;
    margin-bottom: 3rem;
    @media only screen and (max-width: 768px) {
      font-size: 1.6rem;
      line-height: 1.2;
    }
`

const Headline = styled.h1`
    font-size: 5rem;
    margin: 2rem 0rem 1rem 0rem;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      font-size: 3rem;
    }
`
const Anchor = styled.a`
    display: block;
    padding: 2rem 4rem 2rem 2rem;
    margin-bottom: 1rem;
    background-color: #eee;
    color: #333;
    text-decoration: none;
    font-size: 1.4rem;

    &:hover {
      background-color: #0D5C00;
      color: #fff;
      transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
`

const Title = styled.h3`
    font-size: 2rem;
    margin: 0rem 0rem 1rem 0rem;
    font-weight: 600;
`

const Summary = styled.p`
    font-size: 1.4rem;
    font-weight: 400;
`

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <Intro>
          <img width="100%" src="https://newprag.s3.amazonaws.com/404/404.gif"/>
          <Headline>Pawsitively awful</Headline>
          <p>This isn't a huge site, so the page you're looking for can't be too far away.</p>
          <p>Could it be one of these?</p>
        </Intro>
        <Anchor href="/courses">
          <Title>Courses</Title>
          <Summary>Use this page to view and sign-up for products offered by New Pragmatic.</Summary>
        </Anchor>
        <Anchor href="/intro_product">
          <Title>Intro to Product Design</Title>
          <Summary>This one-week course is intended to provide a peek into the field of product design while also giving you a chance to sample how New Pragmatic functions.</Summary>
        </Anchor>
        <Anchor href="/ux">
          <Title>User Experience Design</Title>
          <Summary>This self-paced course follows a comprehensive series of exercises that exposes designers to a wide variety of methods used in UX Design.</Summary>
        </Anchor>
      </Layout>
    )
  }
}

export default NotFoundPage
